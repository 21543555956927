//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
  props: {
    content: {
      type: Object,
    },
  },
  data() {
    return {
      total: 0,
      tdWidth: 0,
      thWidth: 0,
      list: [],
      showHandleSales: !1,
      currentSelectOrderInfo: {},
      handle_state: 10, //默认0处理中(未处理) 10拒绝(驳回) 20同意 30成功(已完成) 50取消(用户主动撤销) 51取消(用户主动收货)
    };
  },
  components: {
    Paging: Paging,
  },
  computed: {},
  watch: {},
  mounted() {
    this.thWidth = (document.getElementsByClassName('order-list')[0].clientWidth - 400) / 8;
    this.tdWidth = (document.getElementsByClassName('order-list')[0].clientWidth - 400) / 8;
  },
  created() {
    let utils = this.$store.state.utils;
    if (utils.is_record) {
      this.content.page = utils.page;
      this.content.rows = utils.rows;
      utils.is_record = !1;
    }
    this.getList();
  },
  methods: {
    getList() {
      let content = this.content;
      let data = {
        page: content.page,
        rows: content.rows,
        create_time: content.create_time,
      };
      if (content.payment_code != 'whole') {
        data.payment_code = content.payment_code;
      }
      if (content.refund_state > 0) {
        data.refund_state = content.refund_state;
      }
      if (content.keywords) {
        data.keywords_type = content.keywords_type;
        data.keywords = content.keywords;
      }
      this.$axios.post(this.$api.order.salesList, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          for (let i in list) {
            let handleState = ''; //1：等待退款处理 2：同意申请，等待买家退货  3：买家已发货，等待收货 4:已收货，确认退款 5:退款成功 6:退款关闭 7同意退款，仅退款 8:拒绝(驳回)
            let handle_state = list[i].handle_state;
            if (handle_state == 0) {
              handleState = 1;
            }
            if (handle_state == 20 && list[i].refund_type == 2 && !list[i].tracking_no && !list[i].tracking_time) {
              handleState = 2;
            }
            if (handle_state == 20 && list[i].refund_type == 2 && list[i].tracking_no != null && list[i].tracking_time > 0 && list[i].receive == 1) {
              handleState = 3;
            }
            if (handle_state == 20 && list[i].refund_type == 2 && list[i].tracking_no != null && list[i].tracking_time > 0 && list[i].receive == 2) {
              handleState = 4;
            }
            if (handle_state == 30) {
              handleState = 5;
            }
            if (list[i].is_close == 1 && (handle_state == 50 || handle_state == 51)) {
              handleState = 6;
            }
            if (handle_state == 20 && list[i].refund_type == 1) {
              handleState = 7;
            }
            if (handle_state == 10) {
              handleState = 8;
            }
            if (list[i].refund_type == 1) {
              if (list[i].is_close == 0 && handle_state >= 20 && list[i].handle_time > 0) handleState = 9;
            } else {
              if (list[i].is_close == 0 && handle_state >= 20 && list[i].handle_time > 0 && !list[i].tracking_no && !list[i].tracking_time && list[i].receive == 2) handleState = 9;
            }
            list[i].handle_state_desc =
              handleState == 1
                ? '待处理'
                : handleState == 2
                ? '待买家发货'
                : handleState == 3
                ? '待商家收货'
                : handleState == 4
                ? '确认收货并退款'
                : handleState == 5
                ? '退货退款成功'
                : handleState == 6
                ? '退款关闭'
                : handleState == 7
                ? '退款成功'
                : handleState == 8
                ? '驳回申请'
                : '代补款';
            list[i].handleState = handleState;
          }
          this.total = res.result.total_number;
          this.list = list;
          this.$nextTick(() => {
            console.log(document.getElementsByClassName('table-body')[0].scrollHeight);
            console.log(document.getElementsByClassName('table-body')[0].clientHeight);
            if (document.getElementsByClassName('table-body')[0].scrollHeight > document.getElementsByClassName('table-body')[0].clientHeight) {
              this.tdWidth = (document.getElementsByClassName('order-list')[0].clientWidth - 400) / 8;
              this.thWidth = (document.getElementsByClassName('order-list')[0].clientWidth - 413) / 8;
            } else {
              this.thWidth = (document.getElementsByClassName('order-list')[0].clientWidth - 400) / 8;
              this.tdWidth = (document.getElementsByClassName('order-list')[0].clientWidth - 400) / 8;
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    updateData(val, status) {
      if (status == 0) {
        this.content.rows = val;
      } else {
        this.content.page = val;
      }
      this.getList();
    },
    salesHandle(id, status) {
      this.$router.push({
        path: '/goods/salesHandle',
        query: {
          id: id,
          status: status,
          page: this.content.page,
          rows: this.content.rows,
        },
      });
    },
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
