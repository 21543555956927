//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SalesRefund from './components/salesTable'
export default {
  components: {
    SalesRefund
  },
  data() {
    return {
      form: {
        page: 1,
        rows: 10,
        payment_code: 'whole', //支付方式
        keywords_type: '', //order_no — 订单号 receiver_phone 收货人电话 receiver_name 收货人姓名 goods_name 商品名称 refund_sn 退款订单号 store_id 店铺id
        keywords: '', //搜索内容
        create_time: [], //下单时间
        refund_state: 0 // 0 全部 1 申请退款，等待商家确认 2同意申请，等待买家退货 3买家已发货，等待收货 4已收货，确认退款 5退款成功 6退款关闭 7同意退款，仅退款 8拒绝(驳回)
      },
      keywords_type: {
        refund_sn: '',
        order_no: '',
        goods_name: '',
        receiver_phone: '',
        receiver_name: '',
        store_id: ''
      },
      pay_type: [
        {
          val: 'whole',
          label: '全部'
        },
        {
          val: 10,
          label: '微信支付'
        },
        {
          val: 20,
          label: '支付宝支付'
        },
        {
          val: 30,
          label: '华为支付'
        },
        {
          val: 40,
          label: '余额支付'
        },
        {
          val: 50,
          label: '会员卡支付'
        }
      ],
      timeVal: '',
      refundNum: {}
    }
  },

  created() {
    if (this.$route.query.type && this.$route.query.type == 1) {
      this.form.refund_state = '1'
    }
    this.getRefundNum()
  },

  methods: {
    //搜索
    serarch() {
      this.$refs.salesRefund.getList()
      this.getRefundNum()
    },
    //清空搜索
    cancelSearch() {
      for (let i in this.keywords_type) this.keywords_type[i] = ''
      this.timeVal = ''
      this.form.payment_code = 'whole'
      this.form.keywords_type = ''
      this.form.keywords = ''
      this.form.create_time = []
      this.getRefundNum()
      this.$refs.salesRefund.getList()
    },
    tabClick(val) {
      this.$refs.salesRefund.page = 1
      this.$refs.salesRefund.rows = 10
      this.$refs.salesRefund.getList()
    },
    //获取订单数量
    getRefundNum() {
      let form = this.form
      let data = {
        page: form.page,
        rows: form.rows,
        create_time: form.create_time
      }
      if (form.payment_code != 'whole') {
        data.payment_code = form.payment_code
      }
      if (form.refund_state > 0) {
        data.refund_state = form.refund_state
      }
      if (form.keywords) {
        data.keywords_type = form.keywords_type
        data.keywords = form.keywords
      }
      this.$axios.post(this.$api.order.refundNum, data).then((res) => {
        if (res.code == 0) {
          this.refundNum = res.result
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //处理搜索内容
    serarchType(type, val) {
      let keywords_type = this.keywords_type
      let form = this.form
      form.keywords = val
      for (let i in keywords_type) keywords_type[i] = ''
      switch (type) {
        case 1:
          keywords_type.refund_sn = val
          form.keywords_type = 'refund_sn'
          break
        case 2:
          keywords_type.order_no = val
          form.keywords_type = 'order_no'
          break
        case 3:
          keywords_type.goods_name = val
          form.keywords_type = 'goods_name'
          break
        case 4:
          keywords_type.receiver_name = val
          form.keywords_type = 'receiver_name'
          break
        case 5:
          keywords_type.receiver_phone = val
          form.keywords_type = 'receiver_phone'
          break
        case 6:
          keywords_type.store_id = val
          form.keywords_type = 'store_id'
          break
      }
    },
    //获取申请时间
    getTime(val) {
      this.form.create_time = []
      for (let i in val) this.form.create_time.push(val[i].getTime() / 1000)
      if (this.form.create_time[0] == this.form.create_time[1])
        this.form.create_time[1] = this.form.create_time[1] + 86400
    }
  }
}
