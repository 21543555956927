import { render, staticRenderFns } from "./salesTable.vue?vue&type=template&id=5000a4de&scoped=true&"
import script from "./salesTable.vue?vue&type=script&lang=js&"
export * from "./salesTable.vue?vue&type=script&lang=js&"
import style0 from "./salesTable.vue?vue&type=style&index=0&id=5000a4de&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5000a4de",
  null
  
)

export default component.exports